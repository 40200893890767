<template>
  <div class="content md flex-box vertical">
    <data-list
      ref="dataList"
      class="flex-grow"
      :url="url"
      query="&sort=-id&expand=keTang"
      entity-name="固定资产盘点"
      hide-show-detail
      hide-edit
      hide-delete
      show-row-num
      :columns="columns"
      :search-key-type="searchKeyType"
      :requestConfig="{ noTempleFilter: true }"
      :row-selection="{ selectedRowKeys, onChange: onSelectChange }"
      :action="action"
    >
      <template v-slot:button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "批量导出" }}
        </a-button>
        <a-dropdown
          :trigger="['click']"
          :disabled="selectedRowKeys.length == 0"
          v-action:update
        >
          <a-button type="primary">批量处理</a-button>
          <a-menu slot="overlay">
            <a-menu-item :key="1" @click="batchStatus(1)">存在</a-menu-item>
            <a-menu-item :key="0" @click="batchStatus(0)">不存在</a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input
            v-model="form.keywords"
            placeholder="输入物品名称、资产编码"
            allowClear
            style="width: 200px;"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker
            v-model="form.check_time"
            :placeholder="['开始日期', '结束日期']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            allowClear
            @change="updateList"
            class="range-picker"
          />
        </a-form-model-item>
      </template>

      <template v-slot:action="{ item }">
        <a class="txt-btn" @click.stop="changeStatus(item)" v-action:update>
          修改盘点状态
        </a>
      </template>
    </data-list>

    <!--    修改盘点状态-->
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="修改盘点状态"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-form-model
        class="item-form"
        ref="editForm"
        :model="formData"
        :rules="formRules"
        :labelCol="modalForm.labelCol"
        :wrapperCol="modalForm.wrapperCol"
        :layout="modalForm.layout"
        :labelAlign="modalForm.labelAlign"
      >
        <a-form-model-item label="盘点状态" prop="checkstatus">
          <a-select
            placeholder="选择盘点状态"
            :options="inventoryStatusOptions"
            v-model="formData.checkstatus"
            allowClear
          >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { inventoryStatusOptions } from "../../common/asset/type";
import {
  clone,
  downLoadBlobFile,
  getKeyTitle,
  getQuery,
  isEmpty,
} from "../../common/js/tool";
import { getTemple } from "../../common/js/storage";

export default {
  name: "FixedInventory",
  data() {
    return {
      url: "/admin/asset-check",
      searchKeyType: {
        keywords: function(form, key) {
          let value = form[key];
          let res = "";
          if (!isEmpty(value)) {
            res = `&filter[or][0][itemname][like]=${value}&filter[or][1][asset_code][like]=${value}`;
          }
          return res;
        },
        check_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&filter[${key}][gte]=${value[0]} 00:00:00&filter[${key}][lte]=${value[1]} 23:59:59`;
          }
          return res;
        },
      },
      columns: [
        { title: "盘点时间", dataIndex: "check_time" },
        { title: "资产编码", dataIndex: "asset_code" },
        { title: "物品名称", dataIndex: "itemname" },
        { title: "堂口", dataIndex: "keTang", customRender: text => text?.name || '--' },
        {
          title: "盘点状态",
          dataIndex: "checkstatus",
          customRender: (text) => {
            return (
              <a-tag color={text ? "orange" : ""}>
                {getKeyTitle(inventoryStatusOptions, text, "value", "label")}
              </a-tag>
            );
          },
        },
      ],
      inventoryStatusOptions,
      /** 弹窗配置 */
      modalForm: { layout: "vertical", labelAlign: "left" },
      formRules: {
        checkstatus: [
          { required: true, message: "请选择盘点状态", trigger: "change" },
        ],
      },
      formData: {},
      visible: false,
      exporting: false,
      selectedRowKeys: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {},
  methods: {
    getList() {
      this.$refs.dataList.getList();
    },
    updateList() {
      this.$refs.dataList.updateList();
    },
    // 修改盘点状态
    changeStatus(item) {
      this.formData = clone(item);
      this.visible = true;
    },
    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      this.selectedRows = rows;
    },
    batchStatus(status) {
      const keys = this.selectedRowKeys;
      if (keys.length > 0) {
        this.$confirm({
          title: "提示",
          content: `确定处理吗？`,
          onOk: () => {
            const url = `${this.url}/update-status`;
            this.$axios({
              url,
              method: "POST",
              data: {
                ids: keys.join(","),
                checkstatus: status,
              },
              noTempleFilter: true,
            }).then((res) => {
              if (res.error == 0) {
                this.$message.success("一键处理成功");
                this.updateList();
              } else {
                this.$message.warning(res.msg || "一键处理失败");
              }
            });
          },
        });
      } else {
        this.$message.warning("请至少选择一条待审核状态的数据");
      }
    },
    cancel() {
      this.formData = {};
      this.visible = false;
    },
    confirm() {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = this.formData;
          this.$axios({
            url: `${this.url}/${form.id}`,
            method: "PATCH",
            data: { checkstatus: form.checkstatus },
          }).then(() => {
            this.$message.success("更新成功");
            this.visible = false;
            this.getList();
          });
        }
      });
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let temple = getTemple();
      const searchKeyType = {
        keywords: function(form, key) {
          let value = form[key];
          let res = "";
          if (!isEmpty(value)) {
            res = `&itemname=${value}&asset_code=${value}`;
          }
          return res;
        },
        check_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&check_start_time=${value[0]}&check_end_time=${value[1]}`;
          }
          return res;
        },
      };
      let url =
        `${this.url}/export?temple_id=${temple}` +
        getQuery.call(this.$refs.dataList, searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "固定资产盘点数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #909090;
  font-size: 12px;
  margin-top: 8px;
}
</style>
